import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Typography } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import { styled } from "@mui/material/styles";

import styles from "./listItems.module.css";


export const headerTabItems = (
  <React.Fragment>
      <button className={styles.hoverHeaderButton} href="https://bettorgames.chalklineapi.com/V3/Dashboard">
          <Typography variant="h6" noWrap sx={{ fontSize: 13, fontWeight: 600 }}>
              <div>BettorGames</div>
          </Typography>
      </button>
      <button className={styles.hoverHeaderButton} href="https://bettoranalytics.com/">
          <Typography variant="h6" noWrap sx={{ fontSize: 13, fontWeight: 600 }}>
              <div>BettorAnalytics</div>
          </Typography>
      </button>
      <button className={styles.mainHeaderButton} href="/">
          <Typography variant="h6" noWrap sx={{ fontSize: 13, fontWeight: 600 }}>
              <div>ChalklineTools</div>
          </Typography>
      </button>
  </React.Fragment>
);

const iconColor = "#3699FE";

const Icon1 = styled(LeaderboardOutlinedIcon)({
    color: iconColor, // Set the desired color here
});
const Icon2 = styled(CodeIcon)({
    color: iconColor, // Set the desired color here
});


export const mainListItems = (
    <React.Fragment>
        <button className={styles.navButton}>
            <ListItemIcon>
                <Icon1 />
            </ListItemIcon>
            <Typography variant="h6" color="white" noWrap sx={{ fontSize: 15 }}>
                <div>Home</div>
            </Typography>
        </button>
        <button className={styles.navButton}>
            <ListItemIcon>
                <Icon2 />
            </ListItemIcon>
            <Typography variant="h6" color="white" noWrap sx={{ fontSize: 15 }}>
                <div>Embed Generator</div>
            </Typography>
        </button>
    </React.Fragment>
);




export const secondaryListItems = (
    <div></div>
    //   <React.Fragment>
    //     <ListSubheader component="div" inset>
    //       Saved reports
    //     </ListSubheader>
    //     <ListItemButton>
    //       <ListItemIcon>
    //         <AssignmentIcon />
    //       </ListItemIcon>
    //       <ListItemText primary="Current month" />
    //     </ListItemButton>
    //     <ListItemButton>
    //       <ListItemIcon>
    //         <AssignmentIcon />
    //       </ListItemIcon>
    //       <ListItemText primary="Last quarter" />
    //     </ListItemButton>
    //     <ListItemButton>
    //       <ListItemIcon>
    //         <AssignmentIcon />
    //       </ListItemIcon>
    //       <ListItemText primary="Year-end sale" />
    //     </ListItemButton>
    //   </React.Fragment>
);
