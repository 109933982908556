import React from 'react'
import MainPage from '../components/MainPage/MainPage'

function MainPageView() {


  return (
    <div><MainPage/></div>
  )
}

export default MainPageView