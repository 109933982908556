import { useState } from "react";
import "./EmbedTool.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Tooltip, IconButton, styled } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the tooltip icon
import UseRedirectToHttps from "../../hooks/useRedirectToHttps";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: "15px", // Adjust padding to make the IconButton smaller
    width: "5px",
    height: "5px",
}));

function embeddedText(
    server,
    token,
    isAccountDetailsEnabled,
    isHeaderEnabled,
    isPromoCardsEnabled,
    overrideThemeName,
    domain
) {
    console.log("embeddedText:", {
        server,
        token,
        isAccountDetailsEnabled,
        isHeaderEnabled,
        isPromoCardsEnabled,
        overrideThemeName,
        domain,
    });

    var overrideThemeNameText = "";
    if (overrideThemeName != "") {
        overrideThemeNameText =
            ",\n                        overideThemeName: '" + overrideThemeName + "'";
    }

    const text =
        `
    <script type="text/javascript">
        (function () {
            const clientConfig = {
                    server: '${server}',
                    target: 'chalkline_iframe_container',
                    token: '${token}',
                    configuration: {
                        isAccountDetailsEnabled: ${isAccountDetailsEnabled},
                        isHeaderEnabled: ${isHeaderEnabled},
                        isPromoCardsEnabled: ${isPromoCardsEnabled}` +
        `${overrideThemeNameText}
                    }
        };
        
        var script = document.createElement('script');
        script.src =` +
        "`https://" +
        domain +
        "/integration/embed.js?ts=${Date.now()}`; \n" +
        `        script.type = 'text/javascript'; \n` +
        `        script.onload = () => {
            window.ChalklineGames.bootstrap(clientConfig);
        };
        
        document.body.appendChild(script);
        })();
    </script>`;

    console.log("TEXT", text);
    return text;
}

// Needed inputs
// server: string
// token: string
// config settings
// isAccountDetailsEnabeled: boolean
// isHeaderEnabled: boolean
// isPromoCardsEnabled: boolean
// overrideThemeName: string
// domain: string

const EmbedToolForm = () => {
    const [showEmbed, setShowEmbed] = useState(false);

    const [server, setServer] = useState("");
    const [token, setToken] = useState("");
    const [isAccountDetailsEnabled, setIsAccountDetailsEnabled] = useState(false);
    const [isHeaderEnabled, setIsHeaderEnabled] = useState(false);
    const [isPromoCardsEnabled, setIsPromoCardsEnabled] = useState(false);
    const [overrideThemeName, setOverrideThemeName] = useState("");
    const [domain, setDomain] = useState("");

    const callDiv = '    <div id="chalkline_iframe_container"></div>';
    const [text, setText] = useState("");

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            switch (name) {
                case "isAccountDetailsEnabled":
                    setIsAccountDetailsEnabled(checked);
                    break;
                case "isHeaderEnabled":
                    setIsHeaderEnabled(checked);
                    break;
                case "isPromoCardsEnabled":
                    setIsPromoCardsEnabled(checked);
                    break;
                default:
                    break;
            }
        } else {
            switch (name) {
                case "server":
                    setServer(value);
                    break;
                case "token":
                    setToken(value);
                    break;
                case "overrideThemeName":
                    setOverrideThemeName(value);
                    break;
                case "domain":
                    setDomain(value);
                    break;
                default:
                    break;
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setShowEmbed(false);

        // Perform any desired actions with the submitted values
        setText(
            embeddedText(
                server,
                token,
                isAccountDetailsEnabled,
                isHeaderEnabled,
                isPromoCardsEnabled,
                overrideThemeName,
                domain
            )
        );

        setShowEmbed(true);

        console.log("DONE");
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <h1 className="title">Embed Generator</h1>

                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="server">
                            <span>* Link to page or game:</span>
                            <Tooltip
                                title={
                                    <p style={{ fontSize: "15px", color: "#DFDFDF" }}>
                                        Use the link to the game lobby
                                        if in doubt.
                                    </p>
                                }
                            >
                                <StyledIconButton size="small" aria-label="tooltip">
                                    <HelpOutlineIcon fontSize="small" />
                                </StyledIconButton>
                            </Tooltip>
                        </label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                id="server"
                                name="server"
                                value={server}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="domain">
                            <span>* Lobby Link:</span>
                            <Tooltip
                                title={
                                    <p style={{ fontSize: "15px", color: "#DFDFDF" }}>
                                        (e.g demo.chalklinegames.com).
                                    </p>
                                }
                            >
                                <StyledIconButton size="small" aria-label="tooltip">
                                    <HelpOutlineIcon fontSize="small" />
                                </StyledIconButton>
                            </Tooltip>
                        </label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                id="domain"
                                name="domain"
                                value={domain}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="token">
                            <span>Token:</span>
                            <Tooltip
                                title={
                                    <p style={{ fontSize: "15px", color: "#DFDFDF" }}>
                                        Token is optional. 
                                        Chalkline can integrate with your authentication module
                                        through tokens, session based redirects, or custom api's.
                                        When using token based authentication, you can use this
                                        parameter to pass Chalkline a player token. For players that
                                        are not logged in, you can specify null or an empty string.
                                    </p>
                                }
                            >
                                <StyledIconButton size="small" aria-label="tooltip">
                                    <HelpOutlineIcon fontSize="small" />
                                </StyledIconButton>
                            </Tooltip>
                        </label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                id="token"
                                name="token"
                                value={token}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-group-row">
                        <label
                            className="col-md-3 col-form-label"
                            htmlFor="isAccountDetailsEnabled"
                        >
                            Allow account creation/sign in:
                        </label>
                        <div>
                            <input
                                type="checkbox"
                                className="form-control-checkbox"
                                id="isAccountDetailsEnabled"
                                name="isAccountDetailsEnabled"
                                checked={isAccountDetailsEnabled}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="isHeaderEnabled">
                            Enable site header:
                        </label>
                        <div>
                            <input
                                type="checkbox"
                                className="form-control-checkbox"
                                id="isHeaderEnabled"
                                name="isHeaderEnabled"
                                checked={isHeaderEnabled}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="isPromoCardsEnabled">
                            Enable promo cards:
                        </label>
                        <div>
                            <input
                                type="checkbox"
                                className="form-control-checkbox"
                                id="isPromoCardsEnabled"
                                name="isPromoCardsEnabled"
                                checked={isPromoCardsEnabled}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-group-row">
                        <label className="col-md-3 col-form-label" htmlFor="overrideThemeName">
                            Override theme name:
                        </label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                id="overrideThemeName"
                                name="overrideThemeName"
                                value={overrideThemeName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="submit-button-container">
                        <button className="submit-button" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>

            {showEmbed ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h2 className="title2">Configured Embeddable Iframe Code</h2>
                    <div>
                        <pre style={{ textAlign: "left" }}>{callDiv}</pre>
                        <pre style={{ textAlign: "left" }}>{text}</pre>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

function EmbedTool() {
    UseRedirectToHttps();
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <EmbedToolForm />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default EmbedTool;
