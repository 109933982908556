import * as React from "react";
import { useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems, headerTabItems } from "../listItems/listItems.js";
import Chart from "../Chart";
import Deposits from "../Deposits";
import Orders from "../Orders";
import EmbedTool from "../EmbedTool/EmbedTool";
import "@fontsource/poppins/400.css";

import chalklinelogo from "../../chalklinelogo.png";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UseRedirectToHttps from "../../hooks/useRedirectToHttps";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://www.chalklinesports.com/">
                Chalkline
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const CustomChevronIcon = styled(ChevronLeftIcon)({
    color: "white", // Set the desired color here
});

const appBarColor = "white";

const drawerWidth = 280;
const drawerColor = "#081136";
//const drawerFontFamily = "Poppins, sans-serif";
//const drawerFontFamily = 'Arial, sans-serif'; // Replace with your desired font family
const drawerFontFamily = "Poppins, sans-serif"; // Set the desired font family

const customTheme = createTheme({
    typography: {
        fontFamily: [
            "Poppins",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Poppins"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: appBarColor,
    color: "black",
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        backgroundColor: appBarColor,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: appBarColor,
        boxShadow: "none",
        color: "black",
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            ...(!open && {
                overflowX: "hidden",
                transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up("sm")]: {
                    width: theme.spacing(9),
                },
            }),
            backgroundColor: drawerColor,
            color: "white",
        },
    })
);

const LogoContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginRight: 30,
    height: 64, // Replace with your desired height
    //backgroundColor: '#FFF', // Replace with the background color of the logo container
    // Add any additional styling for the logo container
});

const Logo = styled("img")({
    height: 45, // Replace with your desired height
    // Add any additional styling for the logo image
});

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function MainPage() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={customTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <List style={{ display: "flex", flexDirection: "row" }}>
                            {headerTabItems}
                        </List>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <LogoContainer>
                            <Logo src={chalklinelogo} alt="chalkline logo" />
                        </LogoContainer>
                        <IconButton onClick={toggleDrawer}>
                            <CustomChevronIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav" sx={{ marginTop: "20px" }}>
                        {mainListItems}
                        <Divider sx={{ my: 1 }} />
                        {secondaryListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        display: "flex", // Set the display to flex
                        flexDirection: "column", // Set the flex direction to column
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Router>
                        <Routes>
                            <Route path="/" element={<EmbedTool />} />
                        </Routes>
                    </Router>
                    <Copyright />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
