import React, {useEffect} from 'react';
import {useLocation} from "react-router";


const UseRedirectToHttps = () => {
    const nodeEnv = process.env.REACT_APP_NODE_ENV; 
    console.log(nodeEnv);
    console.log("UseRedirectToHttps in effect...");
    let location = useLocation();
    console.log("location:", location);
    useEffect(() => {
        
        if (window.location.protocol!=="https:" && nodeEnv == "production") {
            console.log("About to redirect...")
            window.location.replace("https://www.chalklinetools.com"+location.pathname);
            console.log("Redirected...")
        }
    })
};

export default UseRedirectToHttps;