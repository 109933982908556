import logo from './logo.svg';
import './App.css';
import MainPageView from './views/MainPageView';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {
  return (

    <div className="App">
      <MainPageView/>
    </div>
    
  );
}

export default App;
